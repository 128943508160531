import { createAction, props } from '@ngrx/store';
import {
    EquipmentDetail,
    EquipmentPart
} from 'src/app/shared/interfaces/equipment.interface';
import { PartQuantitiesInCart } from 'src/app/shared/interfaces/part-quantities-in-cart.interface';

export const getEquipmentById = createAction(
    '[Equipments] Get equipment by id',
    props<{ id: string }>()
);

export const getEquipmentByIdSuccess = createAction(
    '[Equipments] Get equipment by id success',
    props<{ equipment: EquipmentDetail }>()
);

export const getEquipmentByIdFailure = createAction(
    '[Equipments] Get equipment by id failure',
    props<{ message: string }>()
);

export const setActivePart = createAction(
    '[Equipments] Get active part',
    props<{ part: EquipmentPart | null }>()
);

export const getEquipmentModel = createAction(
    '[Equipments] Get equipment model',
    props<{ equipment: EquipmentDetail }>()
);

export const getEquipmentModelSuccess = createAction(
    '[Equipments] Get equipment model success',
    props<{ modelUrl: string }>()
);

export const getEquipmentModelFailure = createAction(
    '[Equipments] Get equipment model failure',
    props<{ message: string }>()
);


export const setLoadingModel = createAction(
    '[Equipments] Set loading model',
    props<{ loading: boolean }>()
)

export const getCartEquipment = createAction('[Equipments] Get cart equipment');

export const getCartEquipmentSuccess = createAction(
    '[Equipments] Get cart equipment success',
    props<{ partQuantitiesInCart: PartQuantitiesInCart }>()
);

export const getCartEquipmentFailure = createAction(
    '[Equipments] Get cart equipment failure',
    props<{ message: string }>()
);

export const updateCartEquipment = createAction(
    '[Equipments] Update cart equipment',
    props<{ partId: string; quantity: number }>()
);
