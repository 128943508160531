import { equipmentsFeature } from './equipments.reducers';

const {
    selectEquipmentLoading,
    selectEquipment,
    selectActivePart,
    selectModelUrlLoading,
    selectModelUrl,
    selectPartQuantitiesInCart,
} = equipmentsFeature;

export const fromEquipments = {
    selectEquipmentLoading,
    selectEquipment,
    selectActivePart,
    selectModelUrlLoading,
    selectModelUrl,
    selectPartQuantitiesInCart,
}

export {
    selectEquipmentLoading,
    selectEquipment,
    selectActivePart,
    selectModelUrlLoading,
    selectModelUrl,
    selectPartQuantitiesInCart,
};
