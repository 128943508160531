import { createFeature, createReducer, on } from '@ngrx/store';
import * as EquipmentsActions from './equipments.actions';
import {
    EquipmentDetail,
    EquipmentPart
} from 'src/app/shared/interfaces/equipment.interface';
import { PartQuantitiesInCart } from 'src/app/shared/interfaces/part-quantities-in-cart.interface';

export interface EquipmentsState {
    equipmentLoading: boolean;
    modelUrlLoading: boolean;
    modelUrl: string;
    equipment: EquipmentDetail | null;
    activePart: EquipmentPart | null;
    cartCount: number;
    partQuantitiesInCart: PartQuantitiesInCart;
}
export const initialState: EquipmentsState = {
    equipmentLoading: false,
    modelUrlLoading: false,
    modelUrl: '',
    equipment: null,
    activePart: null,
    cartCount: 0,
    partQuantitiesInCart: {},
};
export const equipmentsFeatureKey = 'equipments';
export const equipmentsReducer = createReducer(
    initialState,
    on(EquipmentsActions.getEquipmentById, (state: EquipmentsState) => {
        return {
            ...state,
            activePart: null,
            equipmentLoading: true,
            cartCount: 0
        };
    }),
    on(
        EquipmentsActions.getEquipmentByIdSuccess,
        (state: EquipmentsState, { equipment }) => {
            return {
                ...state,
                equipment: equipment,
                equipmentLoading: false
            };
        }
    ),
    on(EquipmentsActions.getEquipmentByIdFailure, (state: EquipmentsState) => {
        return {
            ...state,
            equipmentLoading: false
        };
    }),
    on(EquipmentsActions.setActivePart, (state: EquipmentsState, { part }) => {
        return {
            ...state,
            activePart: part
        };
    }),
    on(EquipmentsActions.getEquipmentModel, (state: EquipmentsState) => {
        return {
            ...state,
            modelUrl: '',
            modelUrlLoading: true
        };
    }),
    on(
        EquipmentsActions.getEquipmentModelSuccess,
        (state: EquipmentsState, { modelUrl }) => {
            return {
                ...state,
                modelUrl,
                modelUrlLoading: false,
            };
        }
    ),
    on(EquipmentsActions.getEquipmentModelFailure, (state: EquipmentsState) => {
        return {
            ...state,
            modelUrlLoading: false,
        };
    }),
    on(
        EquipmentsActions.getCartEquipmentSuccess,
        (state: EquipmentsState, { partQuantitiesInCart }) => {
            return {
                ...state,
                partQuantitiesInCart
            };
        }
    ),

    on(
        EquipmentsActions.updateCartEquipment,
        (state: EquipmentsState, { partId, quantity }) => {
            return {
                ...state,
                partQuantitiesInCart: {
                    ...state.partQuantitiesInCart,
                    [partId]: quantity + (state.partQuantitiesInCart[partId] || 0)
                }
            };
        }
    )
);

export const equipmentsFeature = createFeature({
    name: equipmentsFeatureKey,
    reducer: equipmentsReducer
});
